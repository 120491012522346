<template>
  <div class="form__filter form__filter-slide">
    <button
      @click.prevent="show = !show"
      :class="!show ? '' : 'active'"
      >
      {{info.data[0].title}}
      <div class="icon icon-arrow-down"></div>
    </button>
    <transition name="slide-fade" mode="out-in" >
      <div class="form__filter__content" v-if="show">
        <div class="form__checkbox" 
          v-for="(data, index) in datas" 
          :key="index"
          >
          <label>
            <input type="checkbox"
              v-model="data.checked"
              v-on:change="check(index, data)"
              
            >
            <span style="font-size: 14px !important;">{{data.name}}</span>
          </label>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>



<script>


export default {
  props: ['info'],
  data: () => ({
    show: false,
    datas: [],
  }),


  created() {
    this.datas = this.info.data
    var app = this
    this.datas.forEach(function(item, index){
      if(item.checked){
        app.show = true
      }
    })
  },
  methods:{

    check(index, data) {
      var index2 = index
      if(this.info.name == 'typeCounterparties'){
        this.datas.forEach(function(item, i){
          if(index2 == i){
          } else{
            item.checked = false
          }
        })
      }

    
      this.$emit('data', {data: this.datas, input: this.info.name})
    }
  }
}
</script>